/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $marker (jQuery element)
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function add_marker( $marker, map ) {

    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
    var icon = new google.maps.MarkerImage(php_vars.sprite_url, new google.maps.Size(60, 73), new google.maps.Point(0, 0));

    // create marker
    var marker = new google.maps.Marker({
      position  : latlng,
      map     : map,
      icon: icon,
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content   : $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open( map, marker );

      });
    }

  }

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length === 1 )
    {
      // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 16 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }

  }

  /*
  *  new_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $el (jQuery element)
  *  @return  n/a
  */

  function new_map( $el ) {

    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom    : 16,
      center    : new google.maps.LatLng(0, 0),
      mapTypeId : google.maps.MapTypeId.ROADMAP,
      styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.attraction",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.attraction",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "lightness": "0"
                },
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "saturation": "0"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.government",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.government",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.medical",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.medical",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.school",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.school",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "saturation": "-100"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        }
    ]
    };

    // create map
    var map = new google.maps.Map( $el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function(){

        add_marker( $(this), map );

    });

    // center map
    center_map( map );

    // return
    return map;

  }

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function() {

    var htmlEl = $('html'),
    bodyEl = $('body'),
    content = $('main'),
    menubtn = $('#nav-icon'),
    isOpen = false;

    // Toggle Menu
    //
    function toggleMenu() {

      if ( bodyEl.hasClass("project-modal-open") ) {
        $('.modal').modal('hide');
      }
      else {

        if( isOpen ) {
          bodyEl.removeClass( 'open-menu' );
          htmlEl.removeClass( 'no-scroll' );
          menubtn.find(".text").text('Menu');
          $.fn.fullpage.setAllowScrolling(true);
          $.fn.fullpage.setKeyboardScrolling(true);
        }
        else {
          bodyEl.addClass( 'open-menu' );
          htmlEl.addClass( 'no-scroll' );
          menubtn.find(".text").text('Close');
          $.fn.fullpage.setAllowScrolling(false);
          $.fn.fullpage.setKeyboardScrolling(false);
        }
        isOpen = !isOpen;

      }

    }

    menubtn.click( function() {
      toggleMenu();
    });
    content.click( function() {
      if ( isOpen ) {
        toggleMenu();
      }
    });
    $(document).on('keyup',function(evt) {
        if (evt.keyCode === 27) {
          if ( isOpen ) {
            toggleMenu();
          }
        }
    });

    // Fullpage.js
    //
    function createFullpage() {
        var fullpage_settings = {
          responsiveWidth: 1200,
          lockAnchors: true,
          verticalCentered: true,
          scrollOverflowReset: true
        };

        var $fullpage = $('#fullpage').fullpage(fullpage_settings);
    }
    createFullpage();

    /*$(window).on('resize load', function() {
      if ( $(window).width() < 991 ) {
        if ( $('html').hasClass('fp-enabled') ) {
          $.fn.fullpage.destroy('all');
        }
        return
      }

      if ( !$('html').hasClass('fp-enabled') ) {
        createFullpage();
      }
    });*/

    $(".scroll-btn").click( function(e) {
      e.preventDefault();
      $.fn.fullpage.moveSectionDown();
    } );

    $(".logo-small").click( function(e) {
      e.preventDefault();
      $.fn.fullpage.moveTo(1);
    } );

    $(window).on('resize load', function() {
      if ( $(window).width() < 991 ) {

        $('ul#menu-primary-navigation li a').click(function(e){
          e.preventDefault();
          newSlide = $(this).closest('li').data("menuanchor");
          toggleMenu();
          setTimeout( function(){
            $('html, body').stop().animate({'scrollTop': $(".section[data-anchor='"+newSlide+"']").offset().top + 1 }, 1200, 'easeInOutExpo');
          }, 450);
        });

      } else {

        $('ul#menu-primary-navigation li a').click(function(e){
          e.preventDefault();
          newSlide = $(this).closest('li').data("menuanchor");
          toggleMenu();
          setTimeout(function(){ $.fn.fullpage.moveTo(newSlide, 1); }, 450);
       });

      }
    });

    // Projects Modalbox
    //
    $('.modal').on('show.bs.modal', function (e) {

      bodyEl.addClass("project-modal-open");
      $.fn.fullpage.setAllowScrolling(false);
      $.fn.fullpage.setKeyboardScrolling(false);
      menubtn.find(".text").text('Close');

    }).on('shown.bs.modal', function (e) {

      var $project_slideshow = $(this).find('.project-slideshow').slick({
        infinite: false,
        arrows: true,
        draggable: true
      }).on('init', function( event, slick ) {
        $(this).addClass('show');
      });

    }).on('hide.bs.modal', function (e) {

      bodyEl.removeClass("project-modal-open");
      menubtn.find(".text").text('Menu');

    }).on('hidden.bs.modal', function (e) {

      $.fn.fullpage.setAllowScrolling(true);
      $.fn.fullpage.setKeyboardScrolling(true);

    });

    // Slick Slider
    //
    var settings = {
      infinite: false,
      arrows: false,
      draggable: false,
      cssEase: 'cubic-bezier(0.7,0,0.3,1)',
    };

    var $projects_slider = $('#projects-slider').slick(settings);

    $projects_slider.on('afterChange', function(event, slick, currentSlide, nextSlide){

      if (currentSlide !== 0) {
        $(".less-projects").removeClass("disabled");
      } else {
        $(".less-projects").addClass("disabled");
      }

      if (slick.$slides.length === currentSlide + slick.options.slidesToScroll) {
        $(".more-projects").addClass("disabled");
      } else {
        $(".more-projects").removeClass("disabled");
      }

      $('.projects-slider-pagination .current-page').text( slick.currentSlide + 1 );
    });

    $(".more-projects").click( function(e) {
      e.preventDefault();
      $projects_slider.slick("slickNext");
    } );

    $(".less-projects").click( function(e) {
      e.preventDefault();
      $projects_slider.slick("slickPrev");
    } );

    // reslick only if it's not slick()
    $(window).on('resize load', function() {
      if ($(document).width() < 991) {
        if ($projects_slider.hasClass('slick-initialized')) {
          $projects_slider.slick('unslick');
        }
        return;
      }

      if (!$projects_slider.hasClass('slick-initialized')) {
        return $projects_slider.slick(settings);
      }
    });

    // Contact Google Maps
    //
    var map = null;
    // Add Map to Contact section
    $('.acf-map').each(function(){
      map = new_map( $(this) );
    });

  });

})(jQuery); // Fully reference jQuery after this point.
